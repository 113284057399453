<template>
  <div v-if="show" style="padding-left:5%; padding-right:5%;">
    <!-- Back Button -->
    <v-row style="margin-bottom: 20px">
      <router-link style="margin-right: 20px" :to="{ name: 'StatisticsCrawlersErrorLog' }">zurück</router-link>
      <v-icon size="40px">$pacman</v-icon>
      <div style="font-size: 30px; margin-left: 20px">Crawlers</div>
    </v-row>
    <v-row>
      <v-col>
        <h2>
          Crawler Error - {{data.type}}
        </h2>
      </v-col>
      <v-col cols="2">
        <v-btn v-if="!data.seen" x-large color="primary" @click="dialog=true">
          Mark as seen
        </v-btn>
        <v-btn v-else x-large color="primary" @click="dialog=true">
          Mark as unseen
        </v-btn>
      </v-col>
    </v-row>
    <div style="max-width: 100%">
      <v-container>
        <v-row>
          <v-col cols="1">
            <b>
              Time:
            </b>
          </v-col>
          <v-col cols="3">
            {{new Date(data.timestamp).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) + ' ' +new Date(data.timestamp).toLocaleTimeString('de-DE')}}
          </v-col>
          <v-col cols="7">
          </v-col>
          <v-col cols="1">
            <v-btn
              elevation="2"
              @click="openLink"
            > link</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>
              Errors :
            </b>
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col>
            <b>
              TimeoutErr:
            </b>
          </v-col>
          <v-col>
            <div v-if="data.timeoutErr === true">
              <v-icon class="green--text" style="cursor: pointer">mdi-check</v-icon>
            </div>
            <div v-else>
              <v-icon class ="red--text" style="cursor: pointer">mdi-close</v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col>
            <b>
              HttpErr:
            </b>
          </v-col>
          <v-col>
            <div v-if="data.httpErr === true">
              <v-icon class="green--text" style="cursor: pointer">mdi-check</v-icon>
            </div>
            <div v-else>
              <v-icon class ="red--text" style="cursor: pointer">mdi-close</v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col>
            <b>
              Selector:
            </b>
          </v-col>
          <v-col>
            <div v-if="data.selector === true">
              <v-icon class="green--text" style="cursor: pointer">mdi-check</v-icon>
            </div>
            <div v-else>
              <v-icon class ="red--text" style="cursor: pointer">mdi-close</v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col>
            <b>
              Redirect:
            </b>
          </v-col>
          <v-col>
            <div v-if="data.redirect === true">
              <v-icon class="green--text" style="cursor: pointer">mdi-check</v-icon>
            </div>
            <div v-else>
              <v-icon class ="red--text" style="cursor: pointer">mdi-close</v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-textarea
            name="input-7-1"
            filled
            label="Notes"
            auto-grow
            v-model="data.notes"
          ></v-textarea>
        </v-row>
        <v-row>
          <v-btn v-if="checkNote()" width="100%" color="green" disabled>
            accept
          </v-btn>
          <v-btn v-else width="100%" color="green" @click="dialogNote = true">
            accept
          </v-btn>
        </v-row>
      </v-container>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card style="padding: 10px">
        <v-card-title class="justify-center">Are you sure?</v-card-title>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            width="40%"
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            width="40%"
            color="green darken-1"
            @click="markAsSeen()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogNote"
      persistent
      max-width="290"
    >
      <v-card style="padding: 10px">
        <v-card-title class="justify-center">Are you sure that you want to change the note?</v-card-title>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            width="40%"
            @click="dialogNote = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            width="40%"
            color="green darken-1"
            @click="patchNote()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <unvergessen-success-dialog :dialog="success" @close="success = false" buttonText="OK">
    </unvergessen-success-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      data: {},
      auxNote: '',
      show: false,
      dialog: false,
      dialogNote: false,
      success: false
    }
  },
  methods: {
    openLink () {
      window.open(this.data.url, '_blank')
    },
    checkNote () {
      if (this.data.notes === this.auxNote) {
        return true
      } else {
        return false
      }
    },
    patchNote () {
      axios.patch('/crawler/error/notes', {
        id: this.data._id,
        notes: this.data.notes
      }).then(res => {
        this.auxNote = this.data.notes
        this.success = true
        this.dialogNote = false
      })
        .catch(() => {})
    },
    markAsSeen () {
      axios.patch('/crawler/error/status', {
        id: this.data._id,
        status: !this.data.seen
      }).then(() => {
        this.dialog = false
        this.success = true
        this.data.seen = !this.data.seen
      })
        .catch(() => {})
    }
  },
  created () {
    axios.post('/crawler/error/details', {
      id: this.id
    }).then(res => {
      this.data = res.data.errors
      this.auxNote = res.data.errors.notes
      this.show = true
    })
      .catch(() => {})
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  }
}
</script>
